import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import ReactToPrint from "react-to-print";

// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../HeadingBack";
import { useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
// import HeadingBack from "../../HeadingBack";

function LiranaSellerComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = navigate;
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;
  const page = navigate?.query?.page == null ? 1 : navigate?.query?.page;
  const search = navigate?.query?.search == null ? "" : navigate?.query?.search;

  const order_list = useSelector((state) =>
    state?.allapi?.get_seller_transaction_list
      ? state?.allapi?.get_seller_transaction_list
      : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    // } else {
    // dispatch(allapiAction.getuserOrderShop(page));
    // }
    getprodctList();
    // dispatch(allapiAction.getInvoice());
    return () => {};
  }, [location]);

  const [productList2, setProductList] = useState();

  const productList = productList2?.map((data) => {
    data.key = data?._id;
    return data;
  });
  console.log(productList);

  const getprodctList = async () => {
    // getKiranaProdutUser
    dispatch(allapiAction.getsellersList(1));
    // const response = await dispatch(allapiAction.getKiranaProdutUser(page));
    // if (response?.success) {
    //   setProductList(response?.data);
    // }
  };

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetailsShop({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetailsShop({ _id: e?._id }));
    // dispatch(allapiAction.getuserOrderDetails({ _id: route?.query?.id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;

  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)

  const basePrice = totalPrice / (1 + gstRate / 100);

  console.log("Base Price (Net Amount): $" + basePrice.toFixed(2));

  const basePriceTotal = basePrice * order_details?.itme_count;

  const perProgst = totalPrice - basePrice;
  console.log(perProgst?.toFixed(2));
  console.log(basePriceTotal?.toFixed(2));

  console.log(order_details);
  const totalgst = order_details?.sub_total - basePriceTotal;
  console.log(totalgst?.toFixed(2));

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount = order_details?.sub_total + shippingCharge;
  // const totalAmount =
  //   order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  console.log(selectedProduct);

  const totalAmounts = selectedProduct?.reduce((total, data) => {
    return total + data?.price * data?.item;
  }, 0);
  const totalItmes = selectedProduct?.reduce((total, data) => {
    return total + parseInt(data?.item);
  }, 0);
  console.log(totalAmounts);
  console.log(totalItmes);

  const henndleToChangePrice = (e, index) => {
    console.log(e);
    console.log(index);

    setSelectedProduct((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index]["item"] = e;
      //   updatedRows[index]["subtotal"] = updatedRows[index]["price"] * e;
      return updatedRows;
    });

    // setSelectedProduct(response);
  };
  //   const handleColorChange = (rowIndex, sizeIndex, field, value) => {
  //     setRows((prevRows) => {
  //       const updatedRows = [...prevRows];
  //       updatedRows[rowIndex][field] = value;
  //       return updatedRows;
  //     });
  //   };

  const onSelectChange = (selectedKeys) => {
    console.log(selectedKeys);
    const filteredProducts = productList.filter((product) =>
      selectedKeys.includes(product._id)
    );
    console.log("filteredProducts", filteredProducts);
    const response = filteredProducts?.map((data) => {
      const filterdata = selectedProduct?.find(
        (data2) => data2?._id == data?._id
      );
      data.item = 1;
      data.subtotal = data.price;
      return filterdata ? filterdata : data;
    });

    setSelectedProduct(response);
    setSelectedRowKeys(selectedKeys);
  };
  console.log(selectedRowKeys);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      // Set the value of the checkbox to the _id field
      value: record._id,
    }),
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Image",
    //   dataIndex: "referId",
    //   key: "referId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {/* {item?.user_id?.fullName} */}
    //           <img
    //             src={URL?.API_BASE_URL + item?.colorId?.featureImage}
    //             alt=""
    //             style={{ width: "70px" }}
    //           />
    //           {/* <p> {item?.user_id?.referId}</p> */}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Sell Code",
    //   dataIndex: "sellCode",
    //   key: "sellCode",
    //   className: "table_action_col",
    // },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    {
      title: "Product name",
      dataIndex: "title",
      key: "title",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.title}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      className: "table_action_col",
    },
    // {
    //   title: "Sponsor Code",
    //   dataIndex: "referByuserId",
    //   key: "referByuserId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.user_id?.referByuserId?.fullName}

    //           <p> {item?.user_id?.referByuserId?.referId}</p>
    //         </span>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Color",
    //   dataIndex: "phone",
    //   key: "phone",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer "
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.colorId?.color}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    // {
    //   title: "Date",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {moment(item?.createdAt).format(" DD/MM/YYYY ")}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    // {
    //   title: "Delivery status",
    //   dataIndex: "delivery_status",
    //   key: "delivery_status",
    // },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => navigate(`/users/contact/${item?.id}`)}
    //           >
    //             <SvgIcon.contacts className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //         {/* <Tooltip title="delete">
    //           <button
    //             className="filter-button ml-3"
    //             onClick={() => showModal(item?.id)}
    //           >
    //             Delete
    //           </button>
    //         </Tooltip> */}
    //         <Tooltip title="Invoice">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewM(item)}
    //           >
    //             Invoice
    //           </button>
    //         </Tooltip>
    //         <Tooltip title="View">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewProduct(item)}
    //           >
    //             View
    //           </button>
    //         </Tooltip>
    //         <Tooltip title="Return Order">
    //           {/* <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //                 hendleToViewReturn(item);
    //             }}
    //           >
    //             <SvgIcon.deletebtn className="scale-icon edit-icon" />
    //             Return Order
    //           </span> */}
    //           {/* <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewReturn(item)}
    //           >
    //             Order Return
    //           </button> */}
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];
  const columns2 = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Image",
    //   dataIndex: "referId",
    //   key: "referId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {/* {item?.user_id?.fullName} */}
    //           <img
    //             src={URL?.API_BASE_URL + item?.colorId?.featureImage}
    //             alt=""
    //             style={{ width: "70px" }}
    //           />
    //           {/* <p> {item?.user_id?.referId}</p> */}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Sell Code",
    //   dataIndex: "sellCode",
    //   key: "sellCode",
    //   className: "table_action_col",
    // },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    {
      title: "Product name",
      dataIndex: "title",
      key: "title",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.title}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      className: "table_action_col",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      className: "table_action_col",
    },

    {
      title: "item",
      dataIndex: "item",
      key: "item",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {/* {item?.item} */}
              <input
                value={item?.item}
                onChange={(e) => {
                  henndleToChangePrice(e?.target?.value, index);
                }}
                type="number"
              />
              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Total Price",
      dataIndex: "subtotal",
      key: "subtotal",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.item * item?.subtotal}
              {/* <input
                value={item?.item}
                onChange={(e) => {
                  henndleToChangePrice(e?.target?.value, index);
                }}
                type="number"
              /> */}
              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Total Price",
    //   dataIndex: "subtotal",
    //   key: "subtotal",
    //   className: "table_action_col",
    // },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    // {
    //   title: "Delivery status",
    //   dataIndex: "delivery_status",
    //   key: "delivery_status",
    // },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => navigate(`/users/contact/${item?.id}`)}
    //           >
    //             <SvgIcon.contacts className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //         {/* <Tooltip title="delete">
    //           <button
    //             className="filter-button ml-3"
    //             onClick={() => showModal(item?.id)}
    //           >
    //             Delete
    //           </button>
    //         </Tooltip> */}
    //         <Tooltip title="Invoice">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewM(item)}
    //           >
    //             Invoice
    //           </button>
    //         </Tooltip>
    //         <Tooltip title="View">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewProduct(item)}
    //           >
    //             View
    //           </button>
    //         </Tooltip>
    //         <Tooltip title="Return Order">
    //           {/* <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //                 hendleToViewReturn(item);
    //             }}
    //           >
    //             <SvgIcon.deletebtn className="scale-icon edit-icon" />
    //             Return Order
    //           </span> */}
    //           {/* <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewReturn(item)}
    //           >
    //             Order Return
    //           </button> */}
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/accounts/order-shop?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/accounts/order-shop?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const [userselete, setuserselete] = useState(null);
  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/productGetforsalingbysalesman?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label =
        data?.productId?.title +
        "-" +
        data?.productcolorId?.color +
        "-" +
        data?.size;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const get_seller_all_list = useSelector((state) =>
    state?.allapi?.get_seller_all_list ? state?.allapi?.get_seller_all_list : {}
  );
  const allsellerList = get_seller_all_list?.docs
    ? get_seller_all_list?.docs
    : [];
  const lost_and_found_pub = useSelector((state) =>
    state?.allapi?.get_seller_all_list ? state?.allapi?.get_seller_all_list : {}
  );

  const route = useNavigate();
  const items = lost_and_found_pub?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, lost_and_found_pub, items]);

  const [shopType, setshopType] = useState("");
  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    const newOffset = (event.selected * itemsPerPage) % items;
    setItemOffset(newOffset);
    setPage(data);
    // dispatch(allapiAction.getsellersList(data));
    // setsearch(e);
    // setPage(1);

    var fanalquery = { page: data };

    if (searchs) {
      fanalquery.key = searchs;
    }
    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (shopType) {
      fanalquery.shopType = shopType;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
    window.scrollTo(50, 0);
  };
  const [state, setState] = useState("");
  const [District, setDistrict] = useState("");

  const [Citys, setCity] = useState("");
  const [CitysList, setCitysList] = useState([]);
  const [pincode, setpincode] = useState("");
  //   const [search, setsearch] = useState("");

  const hendletosearche = (e) => {
    setsearch(e);
    setPage(1);

    var fanalquery = { page: page, key: e };

    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    if (shopType) {
      fanalquery.shopType = shopType;
    }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
  };
  const hendletosearcheStatus = (e) => {
    setshopType(e);
    setPage(1);

    var fanalquery = { page: page, shopType: e };

    if (state) {
      fanalquery.state = state;
    }
    if (District) {
      fanalquery.District = District;
    }
    if (Citys) {
      fanalquery.City = Citys;
    }
    if (pincode) {
      fanalquery.pincode = pincode;
    }
    if (searchs) {
      fanalquery.key = searchs;
    }
    dispatch(allapiAction.getsellersListFilter(fanalquery));
  };
  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Product List"}
            // subHeading={`Total ${order_list?.totalDocs || 0} Product List`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <div className="container-fluid my-3">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 p-1">
              <div className="input-box m-0">
                <i className="uil uil-search"></i>
                <input
                  type="text"
                  placeholder="Search For Vender..."
                  onChange={(e) => {
                    hendletosearche(e?.target?.value);
                  }}
                />
                <i className="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="27"
                    height="27"
                  >
                    <path
                      d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                      fill="rgba(234,173,33,1)"
                    ></path>
                  </svg>
                </i>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 p-1">
              <buttom
                className={
                  shopType == ""
                    ? "btn btn-success ml-3"
                    : "btn btn-primary ml-3"
                }
                onClick={() => {
                  hendletosearcheStatus("");
                }}
              >
                {" "}
                All Seller
              </buttom>
              <buttom
                onClick={() => {
                  hendletosearcheStatus("Online");
                }}
                className={
                  shopType == "Online"
                    ? "btn btn-success ml-3"
                    : "btn btn-primary ml-3"
                }
              >
                Online Seller
              </buttom>
              <buttom
                onClick={() => {
                  hendletosearcheStatus("Offline");
                }}
                className={
                  shopType == "Offline"
                    ? "btn btn-success ml-3"
                    : "btn btn-primary ml-3"
                }
              >
                Offline Seller
              </buttom>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="row">
            {allsellerList &&
              allsellerList?.map((data, i) => {
                return (
                  <div
                    key={i}
                    className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-2"
                    onClick={() => {
                      route("/accounts/kirana-product-order/" + data?._id);
                    }}
                  >
                    <div className="card p-3 text-center">
                      <div className="mb-1">
                        <img
                          src={
                            data?.sellerProfile
                              ? URL?.API_BASE_URL + data?.sellerProfile
                              : "/img/message/logo_95new.png"
                          }
                          alt="card_img"
                          className="img-fluid over_img"
                        />
                      </div>
                      <h4 className="m-0">
                        {data?.displayName ? data?.displayName : "test"}
                      </h4>
                      <small>
                        {data?.district} , {data?.state}
                      </small>
                      {/* <div className="ps-product__rating">
                            <select className="ps-rating" data-read-only="true">
                                <option value="1">1</option>
                                <option value="1">2</option>
                                <option value="1">3</option>
                                <option value="1">4</option>
                                <option value="2">5</option>
                            </select>
                        </div> */}
                      <h5>{data?.phone}</h5>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="container mt-20">
          <nav
            className="d-flex justify-content-between"
            aria-label="Page navigation"
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              className="paging_simple_numbersc pagination"
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
              // forcePage={page}
            />
          </nav>
        </div>
      </div>
    </div>
  );
}

export default LiranaSellerComponent;
