import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
// import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import SvgIcon from "../const/SvgIcon";
// import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
// import { URL } from "../../Redux/common/url";
import HeadingBack from "../../HeadingBack";
// import { useRouter } from "next/router";

// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
// import Image from "next/image";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
import { Image } from "react-bootstrap";
import { URL } from "../../../Redux/common/url";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";
import { toast } from "react-toastify";
import Select from "react-select";
function KiranaProCom() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  // const pagev = navigate?.query?.page;
  // const searchData = navigate?.query?.search;

  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_product_list ? state?.allapi?.get_product_list : {}
  );

  const get_product_details = useSelector((state) =>
    state.allapi.get_product_details ? state.allapi.get_product_details : {}
  );

  const get_main_all_category = useSelector((state) =>
    state?.allapi?.get_main_all_category
      ? state?.allapi?.get_main_all_category
      : []
  );

  const [maincateList, setmaincateList] = useState([]);

  useEffect(() => {
    const dropList = get_main_all_category?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setmaincateList(dropList);
  }, [get_main_all_category]);

  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.searchProduct({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getKiranaProdutVendor(page));
    }
    dispatch(allapiAction.getcateAllList({}));
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
      },
      onCancel() {},
    });
  };

  const hendleDelete = async (e) => {
    // deleteProduct
    // setShowDelete(false);
    await dispatch(
      allapiAction.deleteKiranaProduct({ productId: e, pageNo: page })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    if (search) {
      dispatch(allapiAction.searchProduct({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getKiranaProdutVendor(page));
    }
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [userId, setuserId] = useState("");
  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };
  const showModalAdd = (e) => {
    console.log(e);
    // setcateDetail(e);
    setAddModalOpen(true);
  };
  const [cateDetailAdd, setcateDetailAdd] = useState({});
  console.log(cateDetailAdd);

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };
  const handleOkAdd = async () => {
    if (!cateDetailAdd?.title) {
      toast.error("please enter title");
    } else if (!cateDetailAdd?.price) {
      toast.error("please enter price");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("title", cateDetailAdd?.title);
      // data.append('description', aboutPage)
      data.append("brand", cateDetailAdd?.brand);
      data.append("description", cateDetailAdd?.description);
      data.append("price", cateDetailAdd?.price);
      data.append("categoryId", selectedOptionPr?._id);
      data.append("afterdiscountprice", cateDetailAdd?.price);
      // data.append('categoryName', 'test cate')

      await dispatch(allapiAction.createKiranaProducts(data));

      if (search) {
        dispatch(allapiAction.searchProduct({ page: page, key: search }));
      } else {
        dispatch(allapiAction.getKiranaProdutVendor(page));
      }
      setcateDetailAdd({ title: "", price: "", brand: "", description: "" });
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      return () => {};
    }
  };

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    dispatch(allapiAction.productDetails(e));
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const hendleToStatusfuture = (id, e) => {
    // statusCategory

    dispatch(
      allapiAction.futuredProduct({ productId: id, status: e, pageNo: page })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModals = (e) => {
    console.log(e);
    setcateDetail(e);

    const selectCate = maincateList.find(
      (edata) => edata?._id == e?.categoryId?._id
    );
    console.log(selectCate);

    if (selectCate) {
      setSelectedOptionPr1(selectCate);
    } else {
      setSelectedOptionPr1(null);
    }

    // setImagesfe([{ data_url: URL.API_BASE_URL + e.image }]);
    setIsModalOpen(true);
  };

  const [selectedOptionPr, setSelectedOptionPr] = useState(null);
  const [selectedOptionPr1, setSelectedOptionPr1] = useState(null);
  const handleOk5 = async () => {
    const data = new FormData();

    if (!cateDetail?.title) {
      toast.error("please enter title");
      return;
    }
    if (!cateDetail?.price) {
      toast.error("please enter price");
      return;
    }

    data.append("name", cateDetail?.name);
    // data.append('description', categoryUpPage)
    data.append("meta_title", cateDetail?.meta_title);
    data.append("meta_description", cateDetail?.meta_description);
    data.append("cate_id", cateDetail?._id);
    data.append("pageNo", page);
    // if (Imagesfe[0]?.file) {
    //   // roledata?.role_name
    //   data.append("image", Imagesfe[0]?.file);
    // }
    cateDetail.categoryId = selectedOptionPr1?._id;
    await dispatch(allapiAction.updateKiranaProduct(cateDetail));
    if (search) {
      dispatch(allapiAction.searchProduct({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getKiranaProdutVendor(page));
    }
    // dispatch(adminAction.getRoleList('dhsg'))
    setIsModalOpen(false);
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.image}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: " Category",
      dataIndex: "categoryId",
      key: "categoryId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.categoryId?.name}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.status}>
              <button
                className={
                  item?.status == "Active"
                    ? "success-button ml-3"
                    : "delete-button ml-3"
                }
                onClick={() =>
                  hendleToStatus(
                    item?._id,
                    item?.status == "Active" ? "Inactive" : "Active"
                  )
                }
              >
                {item?.status}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModals(item);
                }}
              >
                <Image
                  src={SvgIcon?.Pencil}
                  alt="Left Arrow"
                  width={20}
                  height={20}
                  style={{ verticalAlign: "middle" }}
                />
                {/* <SvgIcon.Edit className="scale-icon edit-icon" /> */}
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?._id);
                }}
              >
                <Image
                  src={SvgIcon?.deletebtn}
                  alt="Left Arrow"
                  width={20}
                  height={20}
                  style={{ verticalAlign: "middle" }}
                />
                {/* <SvgIcon.deletebtn className="scale-icon edit-icon" /> */}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];
  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/product?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    navigate("/seller/product?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      {/* <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal> */}

      <Modal
        title="Update "
        open={isModalOpen}
        onOk={handleOk5}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <Select
            value={selectedOptionPr1}
            onChange={setSelectedOptionPr1}
            options={maincateList}
            // className="mt-8"
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="title"
            name="title"
            value={cateDetail?.title}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="brand"
            name="brand"
            value={cateDetail?.brand}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="description"
            name="description"
            value={cateDetail?.description}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="price"
            name="price"
            type="number"
            value={cateDetail?.price}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
      </Modal>
      <Modal
        title="Kirana Product"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <Select
            defaultValue={selectedOptionPr}
            onChange={setSelectedOptionPr}
            options={maincateList}
            // className="mt-8"
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="title"
            name="title"
            value={cateDetailAdd?.title}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="brand"
            name="brand"
            value={cateDetailAdd?.brand}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="description"
            name="description"
            value={cateDetailAdd?.description}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="price"
            name="price"
            type="number"
            value={cateDetailAdd?.price}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="price"
            name="price"
            value={cateDetailAdd?.price}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col> */}
      </Modal>

      <Modal
        title="Product Details"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={1000}
      >
        <div>
          <Row>
            <Col span={4}>Name</Col>
            <Col span={8}>{get_product_details?.title}</Col>
            <Col span={4}>Seo Title</Col>
            <Col span={8}>{get_product_details?.seo_title}</Col>
            <Col span={4}>Seo Description</Col>
            <Col span={8}>{get_product_details?.seo_description}</Col>
            <Col span={4}>Price</Col>
            <Col span={8}>{get_product_details?.price}</Col>
            <Col span={4}>Afer Discount Price</Col>
            <Col span={8}>{get_product_details?.afterdiscountprice}</Col>

            <Col span={24}>
              <h3>Variant</h3>
            </Col>
            {get_product_details?.subcolor &&
              get_product_details?.subcolor?.map((data, i) => {
                return (
                  <Col span={24} key={i}>
                    {/* <h6>{data?.color}</h6> */}
                    <Row>
                      <Col span={4}>Color</Col>
                      <Col span={8}>{data?.color}</Col>
                      <Col span={4}>Image</Col>
                      <Col span={8}>
                        <div className="row">
                          {data?.images &&
                            data?.images?.map((Dataimg, indexim) => {
                              return (
                                <div style={{ width: "60px" }} key={indexim}>
                                  <img
                                    src={URL?.API_BASE_URL + Dataimg?.img}
                                    alt=""
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>

                    {data?.productsize &&
                      data?.productsize?.map((data2, i) => {
                        return (
                          <Row key={i}>
                            <Col span={2}>Size</Col>
                            <Col span={2}>{data2?.size}</Col>
                            <Col span={2}>price</Col>
                            <Col span={2}>{data2?.price}</Col>
                            <Col span={4}>after discount price</Col>
                            <Col span={2}>{data2?.afterdiscountprice}</Col>
                            <Col span={2}>stock</Col>
                            <Col span={2}>{data2?.stock}</Col>
                          </Row>
                        );
                      })}

                    <hr />
                  </Col>
                );
              })}
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Product"}
            subHeading={`Total ${get_product_list?.totalDocs || 0} Product`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => {
                showModalAdd();
                // navigate("/seller/product/add-product");
                // window.location.href = "/seller/product/add-product";
              }}
            >
              Add
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        />
      </div>
    </div>
  );
}

export default KiranaProCom;
